<template>
    <div >
        <!-- 蒙版/遮罩层 -->
        <div class="mask-box" id="download-box"  v-if="isweixn">
                <div class="moban">
                    <img src="../assets/liulanqi.png" alt="" class="zhaopian">
                </div>
        </div>
        <div class="content">
            <div class="master">
                <div>
                    <img src="../assets/img/loge.png" alt="" class="logo">
                    <p class="hexiang">禾乡农场</p>
                </div>
            </div>
            <div class="juzhong" @click="is_waibu">
                <div class="xiazai">
                    <p v-if="(anzhuang==false)">下载APP</p>
                    <p v-if="(anzhuang==true)">打开APP</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getDownUrl, } from '../api/index'

    export default{
        data(){
            return{
                isweixn:null,
                isandroid:null,
                iswaibu:false,
                url:'hexiangscheme://host:8888/path',
                anzhuang:false,
                id:'',
                pageName:null,
                idkey:null,
                idvalue:null,
                scene:null,
                UPL:null
            }
        },
        mounted(){
            this.appSource()
            this.is_weixn()
            this.is_android()
            this.UPL = window.location.search
        },
        methods:{
            async appSource() {
                let res = await getDownUrl({
                    planform: this.$route.query.planform ? this.$route.query.planform : ''
                })
                if (res.data.code != 200) return
                this.andriod = res.data.data.andriod_url
                this.ios = res.data.data.ios_url
                console.log( this.andriod,"asdsadad")
                console.log(this.ios)
         },
         is_weixn() {
                var ua = navigator.userAgent.toLowerCase();
                if (ua.match(/MicroMessenger/i) == "micromessenger") {
                    this.isweixn= true;
                } else {
                    this.isweixn= false;
                    setTimeout(()=>{
                        this.is_waibu()
                    },1000) 
                }
                console.log(this.isweixn)
            },
        is_android() {                                                                                                                                                                                                                                                                                                          
            var ua = navigator.userAgent;
            if (ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1) {
                this.isandroid =  true;
            } else {
                this.isandroid =  false;
            }
         },
        // download(){
        //     var url = 'HeXiangscheme://host:8888/path'; //打开app
        //     location.href = url;
        //     console.log(url)
        //     setTimeout(() => {
        //         location.href = url;
        //     }, 2000);
        // },
        is_waibu() {
        //外部浏览器打开
        var isOutBrowser = typeof AndroidWebView;
        var that = this
        if (isOutBrowser == 'undefined') {
            console.log(this.isandroid)
            if ( this.isandroid == true) {
                var loadDateTime = new Date();
                    window.location = that.url + that.UPL;//schema链接或者universal link
                    window.setTimeout(function() { //如果没有安装app,便会执行setTimeout跳转下载页
                        var timeOutDateTime = new Date();
                        if (timeOutDateTime - loadDateTime < 5000) {
                            window.location = that.andriod; //安卓下载地址  
                        } else {
                            window.close();
                        }
                    }, 500);
            }else{
                var loadDateTime = new Date();
                    window.location = that.url + that.UPL;//schema链接或者universal link
                    window.setTimeout(function() { //如果没有安装app,便会执行setTimeout跳转下载页
                        var timeOutDateTime = new Date();
                        if (timeOutDateTime - loadDateTime < 5000) {
                            window.location = that.ios; //ios下载地址  
                        } else {
                            window.close();
                        }
                    }, 500);
            }
        }
        },
       }
    }
</script>

<style scoped>
    .master{
        display: flex;
        justify-content: space-around;
        padding-top: 20%;
    }

    .logo{
        width: 200px;
        height: 200px;
        border-radius: 40px;
    }

    .hexiang{
        font-size:35px;
        margin: 20px;
        font-weight: 900;
    }

    .juzhong{
        display: flex;
        justify-content: space-around;
    }

    .xiazai{
        width: 400px;
        height: 70px;
        border-radius: 40px;
        background-color: #3AD703;
        text-align: center;
        line-height: 70px;
        color: aliceblue;
        font-size: 40px;
        margin: 70px;
    }
    .content{
        width: 100%;
        height: 100%;
    }
    .show-mask-btn {
        background: rgb(4, 208, 71);
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
    }
 
    .show-mask-btn p {
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
    }
    .mask-box {
        position: fixed;
        min-height: 100vh;
        background-color: rgba(0,0,0,.5);
        opacity: 1;
        display: block;
        z-index: 999;
        width: 100%;
    }
    .hint-txt {
        width: 100%;
        color: #ffffff;
        text-align: center;
        height: 35px;
        line-height: 35px;
    }
    .moban{
        display: flex;
        justify-content: flex-end;
    }
    .zhaopian{
        width: 50%;
        z-index: 999;
        margin: 50px;
    }
</style>